.mGraph {
  $block: &;
  &__item {
    @apply relative;
    &:before {
      @apply absolute w-px bg-black-70;
      content: '';
      &.ch1_of_1 {
        @apply md:-top-[12.3rem] -top-[7.3rem] left-0 right-0 mx-auto md:h-48 h-24;
        foo: bar;
      }
      &.ch1_of_2 {
        @apply md:-top-[12.3rem] -top-[7.3rem] md:left-44 left-8 right-0 mx-auto md:h-48 h-24 rotate-[30deg];
        foo: bar;
      }
      &.ch2_of_2 {
        @apply md:-top-[12.3rem] -top-[7.3rem] md:-left-44 -left-8 right-0 mx-auto md:h-48 h-24 -rotate-[30deg];
        foo: bar;
      }
    }
  }
  &__content {
    @apply flex flex-col items-center text-center md:w-72 mx-auto w-32 h-72;
    foo: bar;
    &.yellow {
      #{$block}__title {
        @apply text-yellow-100;
      }
      #{$block}__icon {
        @apply bg-yellow-100;
      }
    }
  }
  &__icon {
    @apply rounded-full bg-gray-50 lg:w-32 lg:h-32 flex items-center justify-center w-20 h-20 min-h-[5rem];
    foo: bar;
    svg {
      @apply w-16 h-16;
    }
  }
  &__row {
    @apply flex lg:gap-x-48 mt-36 gap-x-2;
    foo: bar;
  }
  &__title, &__name {
    @apply text-white text-lg;
  }
  &__title {
    @apply mb-4;
  }
  &__name {
    @apply mt-2;
  }
}
