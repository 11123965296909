@font-face{
    font-family: 'Muller';
    src: url(/public/font/MullerMedium.ttf);
    font-weight: 500;
}

@font-face{
    font-family: 'Muller';
    src: url(/public/font/MullerRegular.ttf);
    font-weight: 400;
}

@font-face{
    font-family: 'Muller';
    src: url(/public/font/MullerBold.ttf);
    font-weight: 700;
}

* {
    font-family: 'Muller', sans-serif;
}

.mBtn {
    @apply inline-block cursor-pointer text-black-100 bg-yellow-100 rounded-[2.5rem] h-10 leading-10 px-5 text-sm font-medium;
}
.mInput span {
    @apply block text-black-100 text-base font-medium;
}
.mInput input {
    @apply block w-full bg-black-20 border border-black-40 rounded-[2.5rem] h-9 leading-9 px-5 text-sm;
}
.mInput input::placeholder {
    @apply text-black-50;
}
.mInput.mInput--footer input {
    @apply bg-transparent w-72;
}
